import React, { useEffect, useState } from 'react'
import { FormContext, useForm } from 'react-hook-form'
import { Card, CardContent, Grid, Text } from 'react-md'

import { handleSubmit, Input, InputDatePicker, InputDecimal, InputDocument, InputNumber, InputPhone } from 'components/form'
import { addressDefaultValues } from 'components/address/config'
import { getEnumDescription } from 'components/address/types'
import { CalloutType } from 'components/core/callout'
import { Cell, Modal } from 'components/core'
import { TextLabel } from 'components/label'
import Address from 'components/address'

import { defaultValues, validationSchema } from './config'
import { handleFix } from './actions'
import { FiscalDocumentEnum } from 'types/order/fiscal-document'

interface ComponentProps {
  validation: any
  visibleModal: boolean
  disableModal: () => void
  onSuccess: () => void
}

const Component: React.FC<ComponentProps> = ({ validation, visibleModal, disableModal, onSuccess }) => {
  const form = useForm({ defaultValues })
  const addressForm = useForm({ defaultValues: addressDefaultValues })
  const [validations, setValidations] = useState<Array<any>>()
  const fiscalDocument = form.watch('fiscalDocument')

  form.register('fiscalDocument')

  useEffect(() => {
    if (validation) {
      if (validation?.order?.state) validation.order.state = getEnumDescription(validation.order.state)
      form.reset(validation.order)
      addressForm.reset(validation.order)
      setValidations(validation.order.notifications)
    } else {
      clear()
      setValidations(undefined)
    }

    return () => {}
  }, [validation, visibleModal])

  const formIsDirty = (): boolean => {
    return !form.formState.dirty && !addressForm.formState.dirty
  }

  const clear = () => {
    form.reset(defaultValues, { dirty: false })
    addressForm.reset(addressDefaultValues, { dirty: false })
  }

  return (
    <Modal
      title={'Correção de Pacote Inválido'}
      visible={visibleModal}
      confirmTitle={'Salvar'}
      confirmDisabled={formIsDirty()}
      width={1200}
      confirmAction={async () => {
        await handleSubmit(form, validationSchema, async () => {
          if (await handleFix({ clientId: validation.clientId, id: validation.id, ...form.getValues(), ...addressForm.getValues() })) {
            clear()
            disableModal()
            onSuccess()
          }
        })
      }}
      closeTitle={'Fechar'}
      closeAction={async () => {
        clear()
        disableModal()
      }}>
      <Grid>
        <Cell>
          {validations && (
            <Grid>
              <Cell>
                <Text type='headline-6' margin='none'>
                  Validações
                </Text>
              </Cell>
              <Cell>
                <Card style={{ backgroundColor: CalloutType.Error }} fullWidth>
                  <CardContent style={{ paddingBottom: '1rem' }}>
                    {validations.map((data: any, index: number) => (
                      <Text key={index} type='body-2' style={{ color: 'black' }} margin={'none'}>
                        {data.message}
                      </Text>
                    ))}
                  </CardContent>
                </Card>
              </Cell>
            </Grid>
          )}
          <FormContext {...form}>
            <Grid>
              <Cell>
                <Text type='headline-6' margin='none'>
                  Informações Principais
                </Text>
              </Cell>
              <Cell>
                <Grid>
                  <TextLabel name='client' label='Cliente' value={validation?.clientFantasyName ?? ''} />
                </Grid>
              </Cell>
              <Cell>
                <Text type='headline-6' margin='none'>
                  Destinatário
                </Text>
              </Cell>
              <Cell>
                <Grid>
                  <Input name='name' label='Nome *' desktopSize={6} tabletSize={8} />
                  <InputDocument name='document' label='Cpf/Cnpj *' maxLength={18} desktopSize={3} tabletSize={4} />
                  <Input name='ie' label='Inscrição Estadual' desktopSize={3} tabletSize={4} />
                </Grid>
              </Cell>
              <Cell>
                <Text type='headline-6' margin='none'>
                  Contatos
                </Text>
              </Cell>
              <Cell>
                <Grid>
                  <Input name='email' label='E-mail' desktopSize={3} tabletSize={4} />
                  <InputPhone name='phone' label='Telefone' desktopSize={3} tabletSize={4} />
                </Grid>
              </Cell>
              <Cell>
                <Text type='headline-6' margin='none'>
                  Endereço Comercial
                </Text>
              </Cell>
              <Cell>
                <Address form={addressForm} />
              </Cell>
              <Cell>
                <Text type='headline-6' margin='none'>
                  Informações Fiscais
                </Text>
              </Cell>
              <Cell>
                <Grid>
                  <InputDecimal name='weight' label='Peso' desktopSize={3} tabletSize={4} />
                  <Input
                    name='orderNumber'
                    label={fiscalDocument == FiscalDocumentEnum.Declaration ? 'Núm. Pedido *' : 'Núm. Pedido'}
                    desktopSize={3}
                    tabletSize={4}
                  />
                  <InputDecimal name='totalValue' label='Valor Declarado *' desktopSize={3} tabletSize={4} />
                  <Input name='nature' label='Descrição Produto/Natureza' desktopSize={3} tabletSize={4} />

                  {fiscalDocument == FiscalDocumentEnum.FiscalNote && (
                    <>
                      <Input name='fiscalNoteNumber' label='Núm. Nota Fiscal *' desktopSize={2} tabletSize={4} />
                      <InputNumber name='fiscalNoteSerie' label='Série Nota Fiscal *' desktopSize={2} tabletSize={4} />
                      <InputDatePicker name='fiscalNoteDate' label='Data Nota Fiscal *' desktopSize={2} tabletSize={4} />
                      <Input name='fiscalNoteAccessKey' label='Chave Nota Fiscal *' desktopSize={6} tabletSize={8} />
                    </>
                  )}
                </Grid>
              </Cell>
            </Grid>
          </FormContext>
        </Cell>
      </Grid>
    </Modal>
  )
}

export default Component
