import React, { useEffect, useState } from 'react'
import { Route } from 'react-router-dom'

import BlockTaskList from 'pages/system/block-task/list'
import CronTaskList from 'pages/system/cron-task/list'

import CarrierEdit from 'pages/system/carrier/edit'
import CarrierNew from 'pages/system/carrier/new'
import CarrierSearch from 'pages/system/carrier/search'

import CompanyEdit from 'pages/system/company/edit'
import CompanyNew from 'pages/system/company/new'
import CompanySearch from 'pages/system/company/search'

import HolidayEdit from 'pages/system/holiday/edit'
import HolidayNew from 'pages/system/holiday/new'
import HolidaySearch from 'pages/system/holiday/search'

import IbgeImport from 'pages/system/ibge/import'
import IbgeSearch from 'pages/system/ibge/search'

import MigrationDataStart from 'pages/system/migration-data/start'

import ProfileEdit from 'pages/system/profile/edit'
import ProfileNew from 'pages/system/profile/new'
import ProfileSearch from 'pages/system/profile/search'

import UserEditAdmin from 'pages/system/user/edit-admin'
import UserEditMe from 'pages/system/user/edit-me'
import UserNew from 'pages/system/user/new'
import UserSearch from 'pages/system/user/search'
import UpdatePassword from 'pages/system/user/update-password'

import { MenuPermission } from 'components/layout/components/menu/types'
import { useAuth } from 'contexts/auth/auth-context'

const Routes: React.FC = () => {
  const { user, getMenuPermission } = useAuth()
  const [menuPermission, setMenuPermission] = useState<MenuPermission>()

  useEffect(() => {
    setMenuPermission(getMenuPermission())

    return () => {}
  }, [user?.permissions])

  return (
    <>
      {menuPermission?.system.blockTask && (
        <>
          <Route path='/system/block-task/list' exact component={BlockTaskList} />
          <Route path='/system/cron-task/list' exact component={CronTaskList} />
        </>
      )}

      {menuPermission?.system.carrier && (
        <>
          <Route path='/system/carrier/:id/edit' exact component={CarrierEdit} />
          <Route path='/system/carrier/new' exact component={CarrierNew} />
          <Route path='/system/carrier/search' exact component={CarrierSearch} />
        </>
      )}

      {menuPermission?.system.company && (
        <>
          <Route path='/system/company/:id/edit' exact component={CompanyEdit} />
          <Route path='/system/company/new' exact component={CompanyNew} />
          <Route path='/system/company/search' exact component={CompanySearch} />
        </>
      )}

      {menuPermission?.system.holiday && (
        <>
          <Route path='/system/holiday/:id/edit' exact component={HolidayEdit} />
          <Route path='/system/holiday/new' exact component={HolidayNew} />
          <Route path='/system/holiday/search' exact component={HolidaySearch} />
        </>
      )}

      {menuPermission?.system.ibge && (
        <>
          <Route path='/system/ibge/import' exact component={IbgeImport} />
          <Route path='/system/ibge/search' exact component={IbgeSearch} />
        </>
      )}

      {menuPermission?.system.migrationData && (
        <>
          <Route path='/system/migration-data/start' exact component={MigrationDataStart} />
        </>
      )}

      {menuPermission?.system.profile && (
        <>
          <Route path='/system/profile/:id/edit' exact component={ProfileEdit} />
          <Route path='/system/profile/new' exact component={ProfileNew} />
          <Route path='/system/profile/search' exact component={ProfileSearch} />
        </>
      )}

      {menuPermission?.system.user && (
        <>
          <Route path='/system/user/:id/edit' exact component={UserEditAdmin} />
          <Route path='/system/user/new' exact component={UserNew} />
          <Route path='/system/user/search' exact component={UserSearch} />
        </>
      )}

      {menuPermission && (
        <>
          <Route path='/system/user/edit' exact component={UserEditMe} />
          <Route path='/system/user/update-password' exact component={UpdatePassword} />
        </>
      )}
    </>
  )
}

export default Routes
