import React, { useEffect, useState } from 'react'
import { Route } from 'react-router-dom'

import OrderDeleteLotClient from 'pages/order/client/delete-lot'
import OrderClient from 'pages/order/client/edit'
import OrderImportCsvClient from 'pages/order/client/import-csv'
import OrderImportManualClient from 'pages/order/client/import-manual'
import OrderImportXmlClient from 'pages/order/client/import-xml'
import OrderContentDeclarationPrintClient from 'pages/order/client/print/content-declaration'
import OrderLabelPrintClient from 'pages/order/client/print/label'
import OrderReportIncidentClient from 'pages/order/client/report/incident'
import OrderReportOrdersWithProblemsClient from 'pages/order/client/report/orders-with-problems'
import OrderReportPerformanceClient from 'pages/order/client/report/performance'
import OrderReportSearchLotClient from 'pages/order/client/report/search-lot'
import OrderClientSearch from 'pages/order/client/search'
import OrderImportValidationClient from 'pages/order/client/import-validation'

import OrderChangePartnerLotFm from 'pages/order/fm/change-partner-lot'
import OrderDeleteLotFm from 'pages/order/fm/delete-lot'
import OrderFm from 'pages/order/fm/edit'
import OrderImportCsvFm from 'pages/order/fm/import-csv'
import OrderImportManualFm from 'pages/order/fm/import-manual'
import OrderImportXmlFm from 'pages/order/fm/import-xml'
import OrderImportValidationFm from 'pages/order/fm/import-validation'
import OrderContentDeclarationPrintFm from 'pages/order/fm/print/content-declaration'
import OrderLabelPrintFm from 'pages/order/fm/print/label'
import OrderReportIncidentFm from 'pages/order/fm/report/incident'
import OrderReportOrders from 'pages/order/fm/report/orders'
import OrderReportOrdersWithProblemsFm from 'pages/order/fm/report/orders-with-problems'
import OrderReportPerformanceFm from 'pages/order/fm/report/performance'
import OrderReportSearchLotFm from 'pages/order/fm/report/search-lot'
import OrderReportStoppedOrders from 'pages/order/fm/report/stopped-orders'
import OrderFmSearch from 'pages/order/fm/search'
import OrderUpdateStatusLotFm from 'pages/order/fm/update-status-lot'

import OrderLogSearch from 'pages/order/log/search'
import OrderLogDetail from 'pages/order/log/detail'

import { MenuPermission } from 'components/layout/components/menu/types'
import { useAuth } from 'contexts/auth/auth-context'

const Routes: React.FC = () => {
  const { user, getMenuPermission } = useAuth()
  const [menuPermission, setMenuPermission] = useState<MenuPermission>()

  useEffect(() => {
    setMenuPermission(getMenuPermission())

    return () => {}
  }, [user?.permissions])

  return (
    <>
      {menuPermission?.order.client && (
        <>
          <Route path='/order/delete/lot/client' exact component={OrderDeleteLotClient} />
          <Route path='/order/:id/edit/client' exact component={OrderClient} />
          <Route path='/order/import/csv/client' exact component={OrderImportCsvClient} />
          <Route path='/order/import/manual/client' exact component={OrderImportManualClient} />
          <Route path='/order/import/xml/client' exact component={OrderImportXmlClient} />
          <Route path='/order/print/content-declaration/client' exact component={OrderContentDeclarationPrintClient} />
          <Route path='/order/print/label/client' exact component={OrderLabelPrintClient} />
          <Route path='/order/report/incident/client' exact component={OrderReportIncidentClient} />
          <Route path='/order/report/orders-with-problems/client' exact component={OrderReportOrdersWithProblemsClient} />
          <Route path='/order/report/performance/client' exact component={OrderReportPerformanceClient} />
          <Route path='/order/report/search-lot/client' exact component={OrderReportSearchLotClient} />
          <Route path='/order/search/client' exact component={OrderClientSearch} />
          <Route path='/order/import/fix/client' exact component={OrderImportValidationClient} />
        </>
      )}

      {menuPermission?.order.fm.changePartnerLot && (
        <>
          <Route path='/order/change-partner/lot' exact component={OrderChangePartnerLotFm} />
        </>
      )}

      {menuPermission?.order.fm.deleteLot && (
        <>
          <Route path='/order/delete/lot' exact component={OrderDeleteLotFm} />
        </>
      )}

      {menuPermission?.order.fm.search && (
        <>
          <Route path='/order/:id/edit' exact component={OrderFm} />
          <Route path='/order/search' exact component={OrderFmSearch} />
        </>
      )}

      {menuPermission?.order.fm.importCsv && (
        <>
          <Route path='/order/import/csv' exact component={OrderImportCsvFm} />
        </>
      )}

      {menuPermission?.order.fm.importManual && (
        <>
          <Route path='/order/import/manual' exact component={OrderImportManualFm} />
        </>
      )}

      {menuPermission?.order.fm.fixImportValidation && (
        <>
          <Route path='/order/import/fix' exact component={OrderImportValidationFm} />
        </>
      )}

      {menuPermission?.order.fm.importXml && (
        <>
          <Route path='/order/import/xml' exact component={OrderImportXmlFm} />
        </>
      )}

      {menuPermission?.order.fm.print.contentDeclaration && (
        <>
          <Route path='/order/print/content-declaration' exact component={OrderContentDeclarationPrintFm} />
        </>
      )}

      {menuPermission?.order.fm.print.label && (
        <>
          <Route path='/order/print/label' exact component={OrderLabelPrintFm} />
        </>
      )}

      {menuPermission?.order.fm.report.incident && (
        <>
          <Route path='/order/report/incident' exact component={OrderReportIncidentFm} />
        </>
      )}

      {menuPermission?.order.fm.report.orders && (
        <>
          <Route path='/order/report/orders' exact component={OrderReportOrders} />
        </>
      )}

      {menuPermission?.order.fm.report.ordersWithProblems && (
        <>
          <Route path='/order/report/orders-with-problems' exact component={OrderReportOrdersWithProblemsFm} />
        </>
      )}

      {menuPermission?.order.fm.report.performance && (
        <>
          <Route path='/order/report/performance' exact component={OrderReportPerformanceFm} />
        </>
      )}

      {menuPermission?.order.fm.report.searchLot && (
        <>
          <Route path='/order/report/search-lot' exact component={OrderReportSearchLotFm} />
        </>
      )}

      {menuPermission?.order.fm.report.stoppedOrders && (
        <>
          <Route path='/order/report/stopped-orders' exact component={OrderReportStoppedOrders} />
        </>
      )}

      {menuPermission?.order.fm.updateStatus && (
        <>
          <Route path='/order/update-status/lot' exact component={OrderUpdateStatusLotFm} />
        </>
      )}

      {menuPermission?.order.log && (
        <>
          <Route path='/order/log/search' exact component={OrderLogSearch} />
          <Route path='/order/log/:id/detail' exact component={OrderLogDetail} />
        </>
      )}
    </>
  )
}

export default Routes
