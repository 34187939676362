import { toast } from 'react-toastify'

import { handleValidate } from 'components/form/form-submit'
import { ValidationMessage } from 'types/core/api'
import { FormList } from 'types/core/form'
import apiOrder from 'services/order/order'
import apiOrderDelete from 'services/order/order-delete-lot'

export const handleValidateForms = async (
  recipientForm: FormList,
  recipientAddressForm: FormList,
  taxInformationForm: FormList,
  observationForm: FormList,
): Promise<boolean> => {
  let success = true

  if (!(await handleValidate(recipientForm.form, recipientForm.validationSchema))) success = false
  if (!(await handleValidate(recipientAddressForm.form, recipientAddressForm.validationSchema))) success = false
  if (!(await handleValidate(taxInformationForm.form, taxInformationForm.validationSchema))) success = false
  if (!(await handleValidate(observationForm.form, observationForm.validationSchema))) success = false

  if (!success) toast.warn(ValidationMessage)

  return success
}

export const handleSave = async (values: any) => {
  let res = await apiOrder.updateFm(values)

  return res?.data
}

export const handleGet = async (id: string) => {
  let res = await apiOrder.getByIdFm(id)

  return res?.data?.data
}

export const handleDelete = async (values: any) => {
  let res = await apiOrderDelete.deleteVolume(values)

  return res?.data?.success
}
