import Yup from 'utils/yup'

export const defaultValues = {
  clientIdV1: null,
  clientV2: {
    id: null,
  },
}

export const validationSchema = Yup.object().shape({
  clientIdV1: Yup.string().nullable().required().max(36, 'O id do cliente deve ter no máximo 36 caracteres'),
  clientV2: Yup.object({
    id: Yup.string().nullable().required(),
  }),
})
