import { LayoutNavigationItem, LayoutNavigationTree } from 'react-md'

import { MenuPermission } from 'components/layout/components/menu/types'

interface getMenuProps {
  permissions: MenuPermission
  menu: LayoutNavigationTree<LayoutNavigationItem>
  getMenu: (id: string, parent: string | null, title: string, icon: string, path: string | null) => LayoutNavigationItem
}

export const getSystemFmMenu = ({ permissions, menu, getMenu }: getMenuProps) => {
  if (permissions?.system.blockTask) {
    menu['/system/block-task/list'] = getMenu('/system/block-task/list', null, 'Bloquear Tarefa', 'task_alt', '/system/block-task/list')
  }

  if (permissions?.system.migrationData) {
    menu['/system/migration-data/start'] = getMenu('/system/migration-data/start', null, 'Migrar Dados', 'sync', '/system/migration-data/start')
  }

  if (permissions?.system.cronTask) {
    menu['/system/cron-task/list'] = getMenu('/system/cron-task/list', null, 'Tarefas Executadas', 'checklist_rtl', '/system/cron-task/list')
  }
}
