import { trackPromise } from 'react-promise-tracker'

import api from 'utils/api'
import { ApiResponse } from 'types/core/api'

const url = '/v1/order/delete'
const Api = {
  deleteLot: (request: any): Promise<ApiResponse> => trackPromise<ApiResponse>(api.post(`${url}/lot`, request)),
  deleteVolume: (request: any): Promise<ApiResponse> => trackPromise<ApiResponse>(api.post(`${url}/volume`, request)),
}

export default Api
