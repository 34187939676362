import React from 'react'
import { Grid } from '@react-md/utils'
import { FontIcon, Text } from 'react-md'

import { ButtonEdit, Cell } from 'components/core'
import { StatusEnum } from 'types/order/status'
import { TextLabel } from 'components/label'

interface ComponentProps {
  order: any
  print: () => Promise<void>
}

const Component: React.FC<ComponentProps> = ({ order, print }) => {
  return (
    <Grid>
      <Cell>
        <Text type='headline-6' margin='none'>
          Informações de Entrega
        </Text>
      </Cell>
      <Cell>
        <Grid>
          <TextLabel name='name' label='Nome do Recebedor' value={order.deliveryInformation?.name} desktopSize={3} tabletSize={8} />
          <TextLabel name='document' label='Documento do Recebedor' value={order.deliveryInformation?.document} desktopSize={2} tabletSize={4} />
          <Cell desktopSize={6} tabletSize={4}>
            <br />
            <div style={{ display: 'flex' }}>
              <ButtonEdit
                text='Imprimir Protocolo'
                icon={<FontIcon>print</FontIcon>}
                disabled={order?.status !== StatusEnum.Delivered}
                onClick={async () => await print()}
              />
            </div>
          </Cell>
        </Grid>
      </Cell>
    </Grid>
  )
}

export default Component
