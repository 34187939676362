import React, { useEffect } from 'react'
import { FormContext, useForm } from 'react-hook-form'
import { Card, CardActions, CardContent, FontIcon, Grid } from 'react-md'

import { ClientAutoComplete } from 'components/auto-complete'
import ButtonSuccess from 'components/core/button-success'
import { handleSubmit, Input } from 'components/form'
import { Cell, PageTitle } from 'components/core'

import { defaultValues, validationSchema } from './config'
import { handleStart } from './actions'

const Component: React.FC = () => {
  const form = useForm({ defaultValues })

  useEffect(() => {
    document.title = 'Migração de Dados'

    return () => {}
  }, [])

  return (
    <Grid>
      <Cell>
        <PageTitle title='Migração de Dados' />
        <Card fullWidth>
          <CardContent>
            <FormContext {...form}>
              <Grid>
                <Cell>
                  <Grid>
                    <Input name='clientIdV1' label='Id Cliente V1 *' desktopSize={3} />
                    <ClientAutoComplete name='clientV2' label='Cliente V2 *' desktopSize={3} />
                  </Grid>
                </Cell>
              </Grid>
            </FormContext>
          </CardContent>
          <CardActions>
            <ButtonSuccess
              text='Realizar Migração'
              icon={<FontIcon>done</FontIcon>}
              disabled={!form.formState.dirty}
              onClick={async () =>
                await handleSubmit(form, validationSchema, async () => {
                  var values = form.getValues({ nest: true })
                  if (await handleStart(values.clientIdV1!, values.clientV2.id!)) form.reset(defaultValues, { dirty: false })
                })
              }
            />
          </CardActions>
        </Card>
      </Cell>
    </Grid>
  )!
}

export default Component
