import React, { useEffect } from 'react'
import { FormContext, useForm } from 'react-hook-form'
import { Grid, Card, CardActions, CardContent, Text, FontIcon, useToggle } from 'react-md'
import { toast } from 'react-toastify'

import { Cell, PageTitle, ButtonSuccess, ConfirmDialog, Callout } from 'components/core'
import { PartnerIntegrationOptions } from 'types/quote/partner-integration'
import { handleSubmit, InputArea, Select } from 'components/form'
import { CalloutType } from 'components/core/callout'

import { defaultValues, validationSchema } from './config'
import { handleSave } from './actions'

const Component: React.FC = () => {
  const form = useForm({ defaultValues })
  const [visibleUpdate, enableUpdate, disableUpdate] = useToggle(false)

  useEffect(() => {
    document.title = 'Atualização de Parceiro'

    return () => {}
  }, [])

  return (
    <>
      <Grid>
        <Cell>
          <PageTitle title='Atualização de Parceiro' />
          <Card fullWidth>
            <CardContent>
              <Callout
                text={
                  'Para sobrescrever a decisão de parceiro do sistema preencher o campo parceiro, dessa forma todos os pedidos listados serão atribuídos para o parceiro informado'
                }
                type={CalloutType.Info}
              />
              <FormContext {...form}>
                <Grid>
                  <Select name='partner' label='Parceiro' options={PartnerIntegrationOptions} desktopSize={6} tabletSize={8} />
                </Grid>
                <Grid>
                  <InputArea name='trackings' label='Trackings *' rows={6} maxRows={6} desktopSize={6} tabletSize={8} />
                  <Cell>
                    <Text type='caption' component='h1'>
                      <i>Para realizar a atualização informe no máximo de 1000 trackings</i>
                    </Text>
                  </Cell>
                </Grid>
              </FormContext>
            </CardContent>
            <CardActions>
              <ButtonSuccess
                text='Atualizar'
                icon={<FontIcon>done</FontIcon>}
                disabled={!form.formState.dirty}
                onClick={async () => enableUpdate()}
              />
            </CardActions>
          </Card>
        </Cell>
      </Grid>

      <ConfirmDialog
        text='Deseja realmente atualizar os pedidos? Essa ação não poderá ser desfeita'
        visible={visibleUpdate ? true : false}
        confirmAction={async () => {
          disableUpdate()
          await handleSubmit(form, validationSchema, async () => {
            let formValues = form.getValues()
            let values: Array<string> = []

            if (formValues.trackings) {
              let itens = formValues.trackings as string
              itens.split('\n').forEach((value: string) => {
                if (value) values.push(value)
              })

              if (values.length > 1000) {
                toast.warn(`Não é permitido atualizar mais de 1000 trackings`)
                return
              }

              await handleSave({ partner: formValues.partner, trackings: values })

              form.reset(defaultValues, { dirty: false })
            }
          })
        }}
        cancelAction={async () => disableUpdate()}
      />
    </>
  )
}

export default Component
