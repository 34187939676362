import React, { useEffect, useState } from 'react'
import { TableCell, TableRow } from 'react-md'
import { format, parseISO } from 'date-fns'

import { getStatusDescription, StatusEnum } from 'types/order/status'
import { DataTableResult } from 'components/data-table/types'
import { DataTable } from 'components/data-table'

import { handleSearch } from './actions'

interface ComponentProps {
  volumeId: string
}

const Component: React.FC<ComponentProps> = ({ volumeId }) => {
  const [filter, setFilter] = useState<any>()
  const [result, setResult] = useState<DataTableResult>({ total: 0, results: [] })

  useEffect(() => {
    if (volumeId) setFilter({ volumeId })

    return () => {}
  }, [volumeId])

  const getPostOfficeTracking = (data: any): string | null => {
    if (data.status !== StatusEnum.WithdrawObjectPostOffice && data.status !== StatusEnum.OrderPostedPostOffice) return null

    var PostOfficeTracking = data.description.split('Código de Rastreio:')[1]

    if (PostOfficeTracking) return ` - Código de Rastreio: ${PostOfficeTracking}`

    return null
  }

  return (
    <DataTable
      filter={filter}
      header={[{ label: 'Data' }, { label: 'Status' }]}
      result={result}
      setResult={setResult}
      search={handleSearch}
      total={result ? result.total : 0}>
      {result &&
        result.results &&
        result.results.map((data: any, index) => (
          <TableRow key={index}>
            <TableCell>{format(parseISO(data.date), "dd'/'MM'/'yyyy' 'HH:mm:ss")}</TableCell>
            <TableCell>
              {getStatusDescription(data.status)}
              {getPostOfficeTracking(data)}
            </TableCell>
          </TableRow>
        ))}
    </DataTable>
  )
}

export default Component
