import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Grid, Card, CardActions, CardContent, FontIcon, CardHeader, CardSubtitle, CardTitle, Text, useToggle, ListItem } from 'react-md'
import { format, parseISO } from 'date-fns'

import { getTicketStatusDescription, TicketStatusEnum } from 'types/ticket/ticket-status'
import { DateTimeLabel, NumberLabel, TextLabel } from 'components/label'
import { Cell, PageTitle, Actions, ButtonEdit } from 'components/core'
import { getTicketTypeDescription } from 'types/ticket/ticket-type'

import { handleGet } from './actions'

import AddComment from './components/add-comment'

const Component: React.FC = () => {
  const { id } = useParams<{ id: string }>()
  const [visibleAddCommentModal, enableAddCommentModal, disableAddCommentModal] = useToggle(false)
  const [visibleActions, enableActions, disableActions] = useToggle(false)
  const [ticket, setTicket] = useState<any>()

  useEffect(() => {
    document.title = 'Edição de Ticket'

    load()

    return () => {}
  }, [id])

  const load = async () => {
    const res = await handleGet(id)
    if (res) setTicket(res)
  }

  const hideActionsButton = ticket?.status == TicketStatusEnum.Closed

  return (
    <>
      <Grid>
        <Cell>
          <PageTitle title='Edição de Ticket' />
          {ticket && (
            <>
              <Card fullWidth style={{ marginBottom: '0.5rem' }}>
                <CardContent>
                  <Grid>
                    <NumberLabel name='id' label='Número' value={ticket.id} desktopSize={2} tabletSize={4} />
                    <DateTimeLabel name='createdAt' label='Data de Abertura' value={ticket.createdAt} desktopSize={3} tabletSize={4} />
                    <TextLabel name='type' label='Tipo' value={getTicketTypeDescription(ticket.type)} desktopSize={3} tabletSize={4} />
                    <TextLabel name='status' label='Status' value={getTicketStatusDescription(ticket.status)} desktopSize={3} tabletSize={4} />
                  </Grid>
                </CardContent>
                <CardActions align='end'>
                  <ButtonEdit text='Ações' icon={<FontIcon>settings</FontIcon>} onClick={async () => enableActions()} disabled={hideActionsButton} />
                </CardActions>
              </Card>

              {ticket &&
                ticket.comments &&
                ticket.comments.map((comment: any, index: number) => (
                  <Card
                    fullWidth
                    key={index}
                    style={comment.isClient ? { marginBottom: '0.5rem' } : { marginBottom: '0.5rem', border: '0.12rem solid #c62828' }}>
                    <CardHeader>
                      <CardTitle small>{comment.userName ?? 'Sistema'}</CardTitle>
                      <CardSubtitle>{format(parseISO(comment.createdAt), "dd'/'MM'/'yyyy' 'HH:mm:ss")}</CardSubtitle>
                    </CardHeader>
                    {comment.comment && (
                      <CardContent>
                        {comment.comment?.split('\n').map((text: any, index: number) => (
                          <Text key={index}>{text}</Text>
                        ))}
                      </CardContent>
                    )}
                    {!comment.comment && <CardContent>Comentário não informado</CardContent>}
                  </Card>
                ))}
            </>
          )}
        </Cell>
      </Grid>

      <Actions visible={visibleActions} disable={disableActions}>
        <ListItem leftAddon={<FontIcon>add</FontIcon>} onClick={() => enableAddCommentModal()}>
          Adicionar Comentário
        </ListItem>
      </Actions>

      {visibleAddCommentModal && <AddComment id={id} disableModal={disableAddCommentModal} load={load} />}
    </>
  )
}

export default Component
