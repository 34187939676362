import { Card, CardContent, Table, TableBody, TableCell, TableContainer, TableHeader, TableRow } from 'react-md'

import { formatCurrency } from 'types/core/currency'
import { Cell } from 'components/core'

interface CountProps {
  states: Array<any>
}

const Component = ({ states }: CountProps) => (
  <Cell>
    <Card fullWidth>
      <CardContent>
        <TableContainer style={{ maxHeight: '20rem' }}>
          <Table fullWidth dense>
            <TableHeader sticky>
              <TableRow>
                <TableCell>UF</TableCell>
                <TableCell>SLA</TableCell>
                <TableCell>Total de Volumes</TableCell>
                <TableCell>Entregas Dentro do SLA</TableCell>
                <TableCell>Entregas Fora do SLA</TableCell>
                <TableCell>Devoluções</TableCell>
                <TableCell>Sinistros</TableCell>
                <TableCell>Em Andamento Dentro do SLA</TableCell>
                <TableCell>Em Andamento Fora do SLA</TableCell>
              </TableRow>
            </TableHeader>
            <TableBody>
              {states.map((value: any, index: any) => (
                <TableRow key={index}>
                  <TableCell>{value.state}</TableCell>
                  <TableCell>{formatCurrency(value.sla)}%</TableCell>
                  <TableCell>{value.totalOrders}</TableCell>
                  <TableCell>{value.totalDeliveredOnTime}</TableCell>
                  <TableCell>{value.totalDeliveredLate}</TableCell>
                  <TableCell>{value.totalDevolution}</TableCell>
                  <TableCell>{value.totalIncident}</TableCell>
                  <TableCell>{value.totalInProgressOnTime}</TableCell>
                  <TableCell>{value.totalInProgressLate}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  </Cell>
)

export default Component
