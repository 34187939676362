import { trackPromise } from 'react-promise-tracker'

import api from 'utils/api'
import { ApiResponse } from 'types/core/api'

const url = '/v1/migration-data'
const Api = {
  start: (alfatrackingV1ClientId: string, alfatrackingV2ClientId: string): Promise<ApiResponse> =>
    trackPromise<ApiResponse>(api.post(`${url}/${alfatrackingV1ClientId}/${alfatrackingV2ClientId}/start`)),
}

export default Api
