import { LayoutNavigationItem, LayoutNavigationTree } from 'react-md'

import { MenuPermission } from 'components/layout/components/menu/types'

interface getMenuProps {
  permissions: MenuPermission
  menu: LayoutNavigationTree<LayoutNavigationItem>
  getMenu: (id: string, parent: string | null, title: string, icon: string, path: string | null) => LayoutNavigationItem
}

export const getInvoicingFmMenu = ({ permissions, menu, getMenu }: getMenuProps) => {
  if (permissions?.invoicing.fm.cteFix || permissions?.invoicing.fm.process || permissions?.invoicing.fm.report.invoicingPreview) {
    menu['/invoicing'] = getMenu('/invoicing', null, 'Faturamento', 'wallet', null)

    if (permissions?.invoicing.fm.cteFix) {
      menu['/invoicing/cte/fix'] = getMenu('/invoicing/cte/fix', '/invoicing', 'Ctes Inválidos', 'build', '/invoicing/cte/fix')
    }

    if (permissions?.invoicing.fm.process) {
      menu['/invoicing/process'] = getMenu('/invoicing/process', '/invoicing', 'Processamento', 'request_quote', '/invoicing/process')
    }

    if (permissions?.invoicing.fm.report.invoicingPreview) {
      menu['/invoicing/report'] = getMenu('/invoicing/report', '/invoicing', 'Relatórios', 'print', null)

      if (permissions?.invoicing.fm.report.invoicingPreview)
        menu['/invoicing/report/invoicing-preview'] = getMenu(
          '/invoicing/report/invoicingPreview',
          '/invoicing/report',
          'Prévia de Faturamento',
          'receipt_long',
          '/invoicing/report/invoicing-preview',
        )
    }
  }
}
