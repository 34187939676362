import React, { useEffect } from 'react'
import { FormContext, useForm } from 'react-hook-form'
import { Grid, Card, CardActions, CardContent } from 'react-md'

import { handleSubmit, Input, InputDatePicker, InputDocument, Select } from 'components/form'
import { Cell, PageTitle, ButtonSearch, ButtonClearSearch } from 'components/core'
import { PartnerIntegrationOptions } from 'types/quote/partner-integration'
import { ClientAutoComplete } from 'components/auto-complete'
import { StatusOptions } from 'types/order/status'

import { defaultValues, validationSchema } from './config'
import { handleReport } from './actions'

const Component: React.FC = () => {
  const form = useForm({ defaultValues })

  useEffect(() => {
    document.title = 'Pedidos'

    return () => {}
  }, [])

  return (
    <Grid>
      <Cell>
        <PageTitle title='Pedidos' />
        <Card fullWidth>
          <CardContent>
            <FormContext {...form}>
              <Grid>
                <ClientAutoComplete name='client' label='Cliente' desktopSize={3} />
                <Input name='recipientName' label='Destinatário' desktopSize={3} tabletSize={4} />
                <InputDocument name='recipientDocument' label='Cpf/Cnpj' maxLength={18} desktopSize={2} tabletSize={4} />
                <Input name='orderNumber' label='Núm. Pedido' desktopSize={2} tabletSize={4} />
                <Input name='fiscalNoteNumber' label='Núm. NF' desktopSize={2} tabletSize={4} />
                <InputDatePicker name='initialCreationDate' label='Data de Criação Inicial' desktopSize={3} tabletSize={4} />
                <InputDatePicker name='finalCreationDate' label='Data de Criação Final' desktopSize={3} tabletSize={4} />
                <InputDatePicker name='initialReceiptDate' label='Data de Recebimento Inicial' desktopSize={3} tabletSize={4} />
                <InputDatePicker name='finalReceiptDate' label='Data de Recebimento Final' desktopSize={3} tabletSize={4} />
                <Select name='status' label='Status' options={StatusOptions} desktopSize={3} tabletSize={4} />
                <Input name='trackingCode' label='Tracking' desktopSize={3} tabletSize={4} />
                <Select name='partner' label='Parceiro' options={PartnerIntegrationOptions} desktopSize={3} tabletSize={4} />
              </Grid>
            </FormContext>
          </CardContent>
          <CardActions>
            <ButtonSearch
              disabled={!form.formState.dirty}
              onClick={async () =>
                await handleSubmit(form, validationSchema, async () => {
                  await handleReport(form.getValues())
                  form.reset(defaultValues, { dirty: false })
                })
              }
            />
            <ButtonClearSearch disabled={!form.formState.dirty} onClick={async () => form.reset(defaultValues, { dirty: false })} />
          </CardActions>
        </Card>
      </Cell>
    </Grid>
  )
}

export default Component
